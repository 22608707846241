import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import './styles.css'; // Custom CSS file for styling

const CookiePolicy = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = sessionStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    sessionStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const handleReject = () => {
    sessionStorage.setItem('cookieConsent', 'rejected');
    setIsVisible(false);
    // Additional handling if necessary, e.g., deleting current cookies
  };

  return (
    <>
      {isVisible && (
        <div className="cookie-policy">
          <div className="cookie-policy-content">
            <p>
              This site uses cookies to improve your experience and to analyze performance and traffic on our website.
              By clicking “Accept All”, you consent to the use of cookies.
            </p>
          </div>
          <div className="cookie-buttons">
            <Button onClick={handleAccept} type="primary" className="cookie-button accept-button">
              Accept All
            </Button>
            <Button onClick={handleReject} className="cookie-button reject-button">
              Reject All
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiePolicy;
