import { ToastContainer } from 'react-toastify';
import './App.css';
import Routes from './routes/index';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks/hooks';
import { checkTokenValidity, setToken } from './utils/checkTokenLocalStorage';
import CookiePolicy from './components/CookiePolicy';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const token = sessionStorage.getItem('token') ?? '';
  const jwTokenExpires = sessionStorage.getItem('jwTokenExpires') ?? '';
  const [isAuthenticatedApp, setIsAuthenticatedApp] = useState(!!token);
  useEffect(() => {
    const checkTokenStatus = () => {
      // Kiểm tra nếu token còn hợp lệ
      const isValid = checkTokenValidity();

      // Phân tích ngày hết hạn
      const expirationDate = new Date(jwTokenExpires);

      // Lấy ngày hiện tại
      const currentDate = new Date();

      // Nếu token đã hết hạn hoặc không hợp lệ, đặt xác thực thành false
      if (!isValid || expirationDate <= currentDate) {
        setIsAuthenticatedApp(false);
      } else {
        setToken(token);
      }
    };

    checkTokenStatus();

    // Xóa timeout khi component unmount
  }, [token, jwTokenExpires]);

  useEffect(() => {
    if (token) {
      setIsAuthenticatedApp(true);
    } else {
      setIsAuthenticatedApp(false);
    }
  }, [token]);


  return (
    <div className="App">
      <ScrollToTop />
      <Routes setIsAuthenticated={setIsAuthenticatedApp} isAuthenticated={isAuthenticatedApp} />
      <ToastContainer />
      <CookiePolicy />
    </div>
  );
}

export default App;
